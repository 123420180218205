import axios from '@/axios.js'

export default {
  fetchOffers({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('/offers')
      .then((response)=>{
        resolve(response.data)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  createOffer({commit},payload){
      return new Promise((resolve,reject)=>{
          axios.post(`/offers`, payload)
          .then((response)=>{
              resolve(response.data.data)
          }).catch((error)=>{
              reject(error)
          })
      })
  },
  updateOffer({commit},payload){
      return new Promise((resolve,reject)=>{
          payload.bodyFormData.set("_method", 'put' );
          axios.post(`/offers/${payload.id}`,payload.bodyFormData)
          .then((response)=>{
              resolve(response)
          }).catch((error)=>{
              reject(error)
          })
      })
  },
  archiveOffer({commit},itemId){
        return new Promise((resolve,reject)=>{
            axios.delete(`/offers/${itemId}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
  offerProfile({commit},itemId){
      return new Promise((resolve,reject)=>{
          axios.get(`/offers/${itemId}`)
          .then((response)=>{
              resolve(response.data)
          }).catch((error)=>{
              reject(error)
          })
      })
  },
  productSKUs({commit}){
    return new Promise((resolve,reject)=>{
        axios.get(`/products/skus`)
        .then((response)=>{
            resolve(response.data.data)
        }).catch((error)=>{
            reject(error)
        })
    })
},
}
